.container {
    margin-top: 25px;
    border-radius: 10px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    background-color: white;
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
}
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #000000;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .titleIcon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
  
  .chapterCount {
    color: #6b7280;
  }
  
  .controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .select {
    appearance: none;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    padding: 0.5rem 2rem 0.5rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  
  .selectWrapper {
    position: relative;
  }
  
  .selectIcon {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  
  .searchInput {
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  
  .table {
    width: 100%;
    justify-content: center;
    border-collapse: collapse;
  }
  
  .tableHeader {
    border-bottom: 1px solid #e5e7eb;
    vertical-align: middle;
  }
  
  .tableHeaderCell {
    text-align: left;
    padding: 0.5rem 0;
    cursor: pointer;
    vertical-align: middle;
  }
  
  .tableHeaderCellRight {
    text-align: right;
    vertical-align: middle;
  }

  .tableHeaderCell svg {
    vertical-align: middle;
  }
  
  .tableRow {
    border-bottom: 1px solid #e5e7eb;
  }

  .hoverBox {
    position: absolute;
    background-color: white;
    border: 1px solid #d1d5db;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    border-radius: 0.25rem;
    z-index: 1000;
    display: none;
  }
  
  .tableRow:hover .hoverBox {
    display: block;
  }

  .tableRow:hover {
    background-color: #ececec;
  }
  
  .tableCell {
    padding: 0.5rem 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .chapterTitle {
    color: #2563eb;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .releaseDate {
    text-align: right;
    color: #6b7280;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .pageButton {
    margin: 0 0.25rem;
    padding: 0.25rem 0.75rem;
    border-radius: 0;
    background-color: #4b556300;
    color: #000000;
    border: none;
    border-bottom: 1px solid #000000;
    cursor: pointer;
  }
  
  .pageButtonActive {
    background-color: #3182ce;
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 0.25rem;
  }
  
  .nextPageButton {
    margin: 0 0.25rem;
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem;
    background-color: #e5e7eb;
  }

  .hoverBoxArrow {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -7px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid rgb(153, 153, 153);
    z-index: 1001;
  }