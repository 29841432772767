@import url('https://fonts.googleapis.com/css2?family=Saira:wght@400;700&display=swap');

.mainContainer {
    padding: 20px;
    color: #ffffff;
    font-family: 'Saira', sans-serif;
}

.innerContent {
    width: 100%;
    margin: 0 auto;
}

.statisticsBar {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-top: 20px;
    flex-wrap: nowrap; /* Ensure all stats stay on the same row */
}

.metric {
    text-align: center;
    background-color: #2e2e2e; /* Dark background */
    border-radius: 10px; /* Rounded edges */
    padding: 10px; /* Add some padding for better appearance */
    flex: 1; /* Allow the item to grow and fill available space */
    min-width: 200px; /* Minimum width of 200px */
    max-width: 100%; /* Ensure it doesn't exceed the container width */
}

.metric:not(:first-child):not(:last-child) {
    margin-left: 20px;
    margin-right: 20px;
}

.metricLabel {
    font-size: 14px;
    color: #aaaaaa; /* Light grey color */
    display: block;
}

.metricValue {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff; /* White color */
    display: block;
}

.fictionCard {
    display: flex;                      /* Set flex */
    flex-direction: column;             /* Align everything up */
    background-color: #2e2e2e;          /* Dark background */
    border-radius: 10px;                /* Rounded edges */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 20px;                      /* Add padding for better appearance */
    margin-bottom: 20px;                /* Space between cards */
}

.fictionHeader {
    display: flex;
    flex-direction: row;                /* Align items horizontally */
    align-items: flex-start;            /* Align items to the top */
    margin-bottom: 20px;
}

.fictionCover {
    margin-top: 12px;
    width: 200px;
    object-fit: cover;
    margin-bottom: 20px;                /* Add margin below the image */
    border-radius: 10px;
}

.fictionButtons {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    gap: 10px;                          /* Add space between buttons */
}

.fictionButtons button {
    background-color: #507dad;          /* Blue background */
    color: #ffffff;                     /* White text */
    border: none;                       /* Remove border */
    border-radius: 5px;                 /* Rounded edges */
    padding: 10px 20px;                 /* Add padding */
    font-size: 16px;                    /* Increase font size */
    cursor: pointer;                    /* Pointer cursor on hover */
    transition: background-color 0.3s;  /* Smooth transition for background color */
}

.fictionButtons button:hover {
    background-color: #0056b3;          /* Darker blue on hover */
}

.fictionInfo {
    flex: 1;
    margin-left: 20px;                  /* Add margin to the left of the info section */
}

.fictionTitle {
    font-size: 28px;
    font-weight: bold;
    margin: 0;
    color: #ffffff;                     /* White color */
}

.fictionSynopsis {
    font-size: 16px;
    color: #aaaaaa;                     /* Light grey color */
    margin-top: 10px;
}

.fictionPatreon {
    font-size: 14px;
    color: #ffffff;                     /* White color */
    margin-top: 10px;
}

.fictionCreatedAt {
    font-size: 14px;
    color: #999999;                     /* Light grey color */
    margin-top: 5px;
}

.fictionRow {
    display: flex;
    flex-direction: column;
}


.saveButton {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.saveButton:hover {
    background-color: #45a049;
}

.saveButton:disabled {
    background-color: #9e9e9e;
    cursor: not-allowed;
}

.tierSetting {
    margin: 10px;
    margin-left: 0px;
    margin-bottom: 20px;
    padding: 10px; /* Added padding */
    padding-left: 20px;
    padding-right: 20px;
    background-color: #272727;
    border-radius: 5px;
    min-width: 250px;
    display: inline-block;
    vertical-align: top;
    gap: 10px; /* Added gap */
}

.tierSetting h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #ffffff;
}

.tierSetting label {
    display: block;
    margin-bottom: 5px;
    color: #cccccc;
}

.tierSetting input[type="number"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #555;
    border-radius: 5px;
    background-color: #3c3c3c;
    color: #ffffff;
    box-sizing: border-box;
}
