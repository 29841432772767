@font-face {
  font-family: "Saira";
  src: url("../../assets/fonts/Saira.ttf") format("truetype");
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 227px;
  height: 100vh;
  background-color: #1a5794;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 0px;
  padding-right: 0px;
  flex-shrink: 0; /* Ensure the navbar does not shrink */
  font-family: "Saira", sans-serif;
  overflow-y: auto; /* Make the navbar scrollable */
  z-index: 10;
}

.scrollableContent {
  flex: 1;
  overflow-y: auto; /* Make the scrollable content scrollable */
  display: flex;
  flex-direction: column; /* Make the nav buttons go one over the other */
  padding-right: 20px; /* Add padding to prevent clipping with the scrollbar */
}

/* Custom scrollbar styles */
.navbar::-webkit-scrollbar {
  width: 0px;
}

.navbar::-webkit-scrollbar-track {
  background: #1a5794;
}

.navbar::-webkit-scrollbar-thumb {
  background-color: #264361;
  border-radius: 10px;
  border: 1px solid #1a5794;
}

.navbar::-webkit-scrollbar-thumb:hover {
  background-color: #3b6b8e;
}

.navbar {
  scrollbar-width: thin;
  scrollbar-color: #264361 #1a5794;
}

@media (max-width: 900px) {
  .navbar {
    display: none; /* Hide navbar on smaller screens */
  }
}

.logo {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-top: 20px;
  display: flex;
  align-items: center; /* Center the words vertically */
  gap: 10px;
  font-family: "Saira", sans-serif;
  position: sticky;
  top: 0;
  background-color: #1a5794; /* Ensure the background color matches the navbar */
  z-index: 1; /* Ensure it stays above other elements */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.logoImage {
  width: 50px;
  height: auto;
}

.navButton {
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  font-family: "Saira", sans-serif;
  transition: background-color 0.3s;
  background-color: #1a5794;
  font-size: 17px;
  color: #ffffff;
  border-radius: 6px;
}

.navButton:hover {
  background-color: #103c6b; /* Change to a darker color when hovered */
}

.divider {
  margin: 20px 0;
  color: rgb(231, 231, 231);
  font-family: "Saira", sans-serif;
}

.dropdown {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-family: "Saira", sans-serif;
  padding: 5px;
  padding-left: 0px;
  border-radius: 5px;
}

.dropdownSelect {
  flex: 1;
  font-family: "Saira", sans-serif;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #103c6b;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdownSelect:hover {
  background-color: #001e3f;
}

.goButton {
  margin-left: 5px;
  font-family: "Saira", sans-serif;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #103c6b;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.goButton:hover {
  background-color: #001e3f;
}

.sectionTitle {
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0 10px;
  font-family: "Saira", sans-serif;
}

.submenu {
  margin-left: 20px;
  font-family: "Saira", sans-serif;
  display: flex;
  flex-direction: column; /* Subbuttons on top of one another */
  animation: slideDown 0.3s ease-out; /* Add sliding animation */
}

@keyframes slideDown {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 500px; /* Adjust as needed */
    opacity: 1;
  }
}

.submenuButton {
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  font-family: "Saira", sans-serif;
  transition: background-color 0.3s;
  font-size: 15px;
  border-radius: 6px;
  color: #ffffff;
}

.submenuButton::before {
  content: "• ";
  color: #ffffff;
  margin-right: 7px; /* Add space between the bullet and the text */
}

.submenuButton:hover {
  background-color: #103c6b; /* Change to a darker color when hovered */
}

.bottomButtons {
  margin-top: auto;
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  padding-bottom: 20px;
  margin-right: 20px;
  font-family: "Saira", sans-serif;
  position: sticky;
  bottom: 0;
  background-color: #1a5794; /* Ensure the background color matches the navbar */
  z-index: 1; /* Ensure it stays above other elements */
}

.bottomButton {
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: center; /* Center the text horizontally */
  font-family: "Saira", sans-serif;
  transition: background-color 0.3s;
  background-color: #10375f;
  font-size: 17px;
  color: #ffffff;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  align-items: center; /* Center the content vertically */
  justify-content: center; /* Center the content horizontally */
  gap: 12px; /* Add a gap between elements */
}

.bottomButton:hover {
  background-color: #093869; /* Change to a darker color when hovered */
}

.bgButton {
  padding: 10px;
  padding-right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: center; /* Center the text horizontally */
  font-family: "Saira", sans-serif;
  transition: background-color 0.3s;
  background-color: #3f75ac;
  font-size: 17px;
  color: #ffffff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  display: flex;
  align-items: center; /* Center the content vertically */
  justify-content: center; /* Center the content horizontally */
  gap: 12px; /* Add a gap between elements */
  border-left: 2px solid #001d47;
}

.bgButtonToggled {
  background-color: #10375f;
}

.bgButton img {
  width: 30px; /* Adjust the width to make the SVG smaller */
  height: 30px; /* Adjust the height to make the SVG smaller */
}

.bgButton:hover {
  background-color: #335e8d; /* Change to a darker color when hovered */
}
