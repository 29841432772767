@font-face {
    font-family: 'Saira';
    src: url('../assets/fonts/Saira.ttf') format('truetype');
}

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #333333;
    color: #ffffff;
    font-family: 'Saira', sans-serif;
}

.innerContent {
    max-width: 800px;
    width: 100%;
    /* background: #333333; */
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); */
    text-align: center;
}

.home-content-view h1, .home-content-view h2 {
    color: #ffffff;
}

.home-content-view h1 {
    font-size: 2em;
}

.home-content-view h2 {
    font-size: 1.5em;
    color: #cccccc;
}

.roadmapContainer {
    margin-top: 20px;
    text-align: left;
}

.roadmapList {
    list-style: none;
    padding: 0;
    position: relative;
}

.roadmapList::before {
    content: "";
    position: absolute;
    top: 0;
    left: 12px;
    bottom: 0;
    width: 2px;
    background-color: #555555;
}

.roadmapList li {
    margin: 20px 0;
    padding-left: 35px;
    position: relative;
}

.roadmapSubList {
    list-style: none;
    padding: 0;
    position: relative;
}

.roadmapSubList::before {
    content: "";
    position: absolute;
    top: 0;
    left: 33px;
    bottom: 0;
    width: 2px;
    background-color: #555555;
}

.roadmapSubList li {
    margin: 20px 0;
    padding-left: 35px;
    position: relative;
}

.checkmark {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #333333;
    z-index: 1;
}

.checkmark::before {
    font-size: 16px;
    color: #ffffff;
}

.checkmark:empty::before {
    content: none;
}

.roadmapList ul {
    list-style: none;
    padding-left: 20px;
    margin-top: 10px;
}

.roadmapList ul li {
    margin: 10px 0;
    padding-left: 20px;
    position: relative;
}

.roadmapList ul li::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    width: 10px;
    height: 2px;
    background-color: #555555;
}

.subCheckmark {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #333;
    z-index: 1;
}

.subCheckmark::before {
    font-size: 16px;
    color: #fff;
}

.subCheckmark:empty::before {
    content: none;
}

.subCheckmark + span {
    margin-left: 15px; /* Adjust the value as needed */
}

.checkmarkList ul {
    list-style: none;
    padding-left: 20px;
    margin-top: 10px;
    position: relative;
}

.checkmarkList ul li {
    margin: 10px 0;
    position: relative;
}

.checkmarkList ul li::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    width: 24px;
    height: 24px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #333;
    z-index: 1;
}

.checkmarkList ul li::after {
    content: "\2713"; /* Unicode checkmark */
    position: absolute;
    top: 4px;
    left: 6px;
    font-size: 16px;
    color: #fff;
}

.bottomLinksContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    padding-bottom: 20px;
}

.link, .button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4e4e4e; /* Changed to dark color */
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
    margin: 10px;
}

.link {
    padding: 10px 15px;
    transition: transform 0.2s;
}

.link:hover {
    transform: scale(1.05);
}

.button {
    padding: 10px 15px;
    border: none;
}

.button:hover {
    background-color: #444444; /* Slightly lighter dark color on hover */
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.15);
}

.linkIcon, .button img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.button span {
    font-size: 16px;
    color: #ffffff;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .bottomLinksContainer {
        align-items: center;
    }

    .link, .button {
        width: 100%;
        max-width: 300px;
        justify-content: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .buttonContainer {
        flex-direction: column;
        align-items: center;
    }
}
