.container {
    background-color: #222222; /* bg-gray-800 */
    color: white;
    padding: 1rem; /* p-4 */
    border-radius: 0.5rem; /* rounded-lg */
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); /* shadow-md */
    max-width: 100%; /* Ensure it doesn't exceed the screen width */
}

@media (max-width: 500px) {
    .container {
        border-radius: 0px; /* rounded-lg */
    }
}

.flexContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem; /* Add spacing between items */
}

.coverImage {
    border-radius: 0.375rem; /* rounded-md */
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); /* shadow-md */
    width: 100%; /* Full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
}

.titleMd {
    font-size: 1.5rem; /* text-2xl */
    margin-top: 0;
    margin-bottom: 0.5rem; /* mb-2 */
}

.textContainer {
    flex-grow: 1; /* flex-grow */
    display: flex;
    flex-direction: column; /* flex-col */
    justify-content: space-between; /* justify-between */
    gap: 0.5rem; /* Add spacing between text elements */
}

.title {
    font-size: 1.25rem; /* text-xl */
    font-weight: bold; /* font-bold */
    margin-bottom: 0px;
}

.author {
    font-size: 1rem; /* text-base */
    align-self: flex-start; /* Align to the start */
    margin-bottom: 0.5rem; /* mb-2 */
}

.chapter {
    font-size: 1.125rem; /* text-lg */
    font-weight: normal; /* Remove bold */
    margin-bottom: 0px;
    margin-top: 0px;
}

.ratingContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem; /* gap-2 */
    margin-bottom: 0.5rem; /* mb-2 */
}

.star {
    width: 1rem; /* w-4 */
    height: 1rem; /* h-4 */
}

.starFilled {
    color: #f6e05e; /* text-yellow-400 */
    fill: currentColor;
}

.starEmpty {
    color: #a0aec0; /* text-gray-400 */
}

.buttonContainer {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    gap: 0.5rem; /* gap-2 */
    align-items: stretch; /* Stretch buttons to full width */
}

.button {
    font-weight: bold;
    padding: 0.75rem; /* p-3 */
    border-radius: 0.375rem; /* rounded-md */
    border: none; /* Remove border */
    color: white;
    width: 100%; /* Full width */
    cursor: pointer;
    text-align: center; /* Center text */
}

.buttonBlue {
    background-color: #3182ce; /* bg-blue-500 */
}

.buttonBlue:hover {
    background-color: #2f7ac0; /* hover:bg-blue-600 */
}

.buttonGreen {
    background-color: #48bb78; /* bg-green-500 */
}

.buttonGreen:hover {
    background-color: #38a169; /* hover:bg-green-600 */
}

.buttonYellow {
    background-color: #ffffff; /* bg-yellow-500 */
    color: #000000;
}

.buttonYellow:hover {
    background-color: #d3d3d3; /* hover:bg-yellow-600 */
    color: #000000;
}

.buttonRed {
    background-color: #e53e3e; /* bg-red-500 */
}

.buttonRed:hover {
    background-color: #cf3131; /* hover:bg-red-600 */
}