@font-face {
    font-family: 'Saira';
    src: url('../assets/fonts/Saira.ttf') format('truetype');
}

.container {
    display: flex;
    justify-content: space-between;
    padding: 0px 5px;
    margin-top: 10px;
    gap: 5px;
  }
  
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    flex: 1;
    font-size: 14px;
    background-color: #3182ce;
    border: 0px solid #d1d5db;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    font-family: 'Saira', sans-serif;
  }
  
  .button:hover:not(:disabled) {
    background-color: #2f7ac0;
    color: #cee7ff;
  }
  
  .button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .buttonIcon {
    width: 16px;
    height: 16px;
  }
  
  .previousButton .buttonIcon {
    margin-right: 8px;
  }
  
  .nextButton .buttonIcon {
    margin-left: 8px;
  }

  .fictionPageButton {
    width: 100%;
    display: none;
    white-space: nowrap;
    margin-top: 5px;
  }

  .hoverBox {
    position: fixed; /* Changed from absolute to fixed */
    transform: translate(-50%, -100%); /* Adjusted to position above the button */
    background-color: rgb(88, 88, 88);
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    z-index: 1000;
    text-align: center;
    width: 150px;
  }

  .hoverBox::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgb(88, 88, 88);
  }

  @media (min-width: 640px) {
    .container {
      padding: 0 5px;
    }
  
    .button {
      min-width: 300px;
      padding: 8px 24px;
      font-size: 16px;
      flex: 0;
    }
  
    .buttonIcon {
      width: 20px;
      height: 20px;
    }

    .previousButton,
    .nextButton,
    .fictionPageButton {
        width: auto;
        margin: 0;
    }

    .fictionPageButton {
        display: flex;
        margin-top: 0;
        justify-content: center;
        align-items: center;
    }
  }
  
  @media (min-width: 768px) {
    .container {
      padding: 0 5px;
    }
  }