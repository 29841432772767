.topNavBar {
    background-color: white;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Saira', sans-serif;
}

.topRow, .bottomRow {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Saira', sans-serif;
}

.topRow {
    padding: 13px 20px;
    justify-content: space-between;
    font-family: 'Saira', sans-serif;
}

.bottomRow {
    justify-content: flex-start;
    font-family: 'Saira', sans-serif;
    background-color: white;
    padding: 15px 20px;
    border-top: 1px solid #e0e0e0;
    font-weight: bold;
    font-family: 'Saira', sans-serif;
}



.left, .right {
    display: flex;
    align-items: center;
    font-family: 'Saira', sans-serif;
}

.navButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    padding-left: 15px;
    font-size: 16px;
    border-radius: 5px;
    color: black;
    font-family: 'Saira', sans-serif;
    display: flex;
    align-items: center; /* Center the content vertically */
}

.navButton:hover {
    color: rgb(29, 87, 121);
    background-color: lightblue; /* Change text color to light blue on hover */
}

.left .navButton {
    font-weight: bold; /* Make the left buttons on the top row bold */
}

.dropdown {
    position: relative;
    display: block;
    font-family: 'Saira', sans-serif;
    width: 100%; /* Make the dropdown take up the full width */
    text-align: left; /* Align text to the left */
}

.dropdownContent {
    padding-top: 10px;
    padding-bottom: 10px;
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    font-family: 'Saira', sans-serif;
    min-width: 160px;
    top: 100%; /* Position the dropdown content below the dropdown button */
    left: 0;
    text-align: left; /* Align text to the left */
}

.dropdown:hover .dropdownContent {
    display: block;
    font-family: 'Saira', sans-serif;
    text-align: left; /* Align text to the left */
}

.dropdownItem {
    padding: 12px 20px;
    cursor: pointer;
    white-space: nowrap;
    font-family: 'Saira', sans-serif;
    background-color: white; /* Set the background color to white */
    border: none; /* Remove borders */
    border-radius: 0; /* Make the edges sharp */
    display: block; /* Make each item take up the full width */
    width: 100%; /* Make the dropdown items take up the full width */
    text-align: left; /* Align text to the left */
}

.dropdownItem:hover {
    color: white;
    background-color: rgb(92, 160, 199); /* Change background color to gray on hover */
    text-align: left; /* Align text to the left */
}

.iconWithCounter {
    position: relative;
    font-family: 'Saira', sans-serif;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 6px;
}

.iconWithCounter:hover {
    color: rgb(29, 87, 121);
    background-color: lightblue; /* Change to a darker color when hovered */
}

.counter {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: rgb(221, 58, 58);
    color: white;
    border-radius: 100%;
    padding: 1px 6px;
    min-width: 8px; /* Ensure the counter is never thinner than a perfect circle */
    font-size: 11px;
    font-family: 'Saira', sans-serif;
    text-align: center; /* Center the text */
}

.userInfo {
    display: flex;
    align-items: center;
    font-family: 'Saira', sans-serif;
    transition: background-color 0.3s;
    padding: 10px 15px;
    border-radius: 6px;
    cursor: pointer;
}

.userInfo:hover {
    color: rgb(29, 87, 121);
    background-color: lightblue; /* Change to a darker color when hovered */
}

.userInfo span {
    margin-right: 10px;
    font-family: 'Saira', sans-serif;
}

.subcategory {
    color: rgba(128, 128, 128, 0.842);
    margin-left: 10px;
    font-size: 13px;
    font-family: 'Saira', sans-serif;
    font-weight: normal;
}