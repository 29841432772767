.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f3f3f3;
    color: #333;
    font-family: 'Saira', sans-serif;
}

.innerContent {
    width: 100%;
    border-radius: 8px;
    text-align: center;
    box-sizing: border-box;
}


.chapterContent {
    width: 100%;
    margin-top: 10px;
    padding: 20px;
    background-color: #ffffff;
    color: #333333;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    overflow-wrap: break-word;
    text-align: left;
}

.chapterContentTable table td p {
    padding-left: 5px;
    padding-right: 5px;
}

@media (max-width: 500px) {
    .mainContainer {
        padding-left: 0;
        padding-right: 0;
    }

    .chapterContent {
        padding-left: 10px;
        padding-right: 10px;
    }
}