@font-face {
  font-family: 'Saira';
  src: url('../assets/fonts/Saira.ttf') format('truetype');
}

.container {
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
  margin-top: 10px;
  gap: 5px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  font-size: 14px;
  background-color: #3182ce;
  border: 0px solid #d1d5db;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  font-family: 'Saira', sans-serif;
  width: 100%;
  height: 40px; /* Set a fixed height for consistency */
}

.button:hover:not(:disabled) {
  background-color: #2f7ac0;
  color: #cee7ff;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.buttonIcon {
  width: 16px;
  height: 16px;
}

.previousButton .buttonIcon {
  margin-right: 8px;
}

.nextButton .buttonIcon {
  margin-left: 8px;
}

.fictionPageButton {
  margin-top: 5px;
}

.hoverBox {
  position: fixed;
  transform: translate(-50%, -100%);
  background-color: rgb(88, 88, 88);
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1000;
  text-align: center;
  width: 150px;
}

.hoverBox::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgb(88, 88, 88);
}

.topRow {
  display: flex;
  gap: 5px;
}

.topRow .button {
  flex: 1;
}

@media (min-width: 1400px) {
  .container {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
  }

  .button {
      min-width: 200px;
      padding: 8px 24px;
      font-size: 16px;
      flex: 0 1 auto;
  }

  .buttonIcon {
      width: 20px;
      height: 20px;
  }

  .fictionPageButton {
      margin-top: 0;
      flex: 0 0 auto;
      width: auto;
  }

  .topRow {
      flex: 1;
      gap: 5px;
      justify-content: flex-start;
  }

  .topRow .button {
      flex: 0 1 auto;
      width: auto;
  }
}