.card {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    background-color: #3d3d3f;
    color: #ffffff;
    border-color: #1a202c;
  }
  
  .content {
    display: flex;
    flex-direction: column;
  }
  
  .imageContainer {
    padding: 1rem;
  }
  
  .coverImage {
    width: 100%;
    max-width: 200px;
    height: auto;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .infoContainer {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  
  .title {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 1.875rem;
    font-weight: bold;
  }
  
  .author {
    margin-top: 0px;
    font-size: 1.25rem;
  }
  
  .tagContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .tag {
    font-size: 0.75rem;
  }
  
  .links {
    font-size: 0.875rem;
  }
  
  .link {
    color: #3182ce;
    text-decoration: none;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  
  .descriptionContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .imageAndTitleContainer {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
  
  .truncate {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-clamp: 3; /* Add this line */
  }
  
  .showMoreButton {
    color: #ffffff;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    text-decoration: none;
    text-decoration: underline;
  }
  
  .showMoreButton:hover {
    text-decoration: underline;
  }
  
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
    margin-right: 15px;
    margin-left: 10px;
  }
  
  .button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    min-width: 200px;
  }
  
  .icon {
    width: 1rem;
    height: 1rem;
  }
  
  .footer {
    background-color: #f7fafc;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footerButton {
    color: #4a5568;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .footerButton:hover {
    color: #1a202c;
  }
  
  .footerButtonGroup {
    display: flex;
    gap: 1rem;
  }
  
  @media (min-width: 768px) {
    .content {
      flex-direction: row;
    }

    .coverImage {
      width: 100%;
      max-width: 200px;
      min-width: 180px;
      height: auto;
      border-radius: 0.5rem;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    }
  
    .infoContainer {
      width: 66.666667%;
    }
  }

  @media (max-width: 767px) {
    .coverImage {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .coverImage {
      width: 100%;
      max-width: 100px;
      min-width: 100px;
      height: auto;
      border-radius: 0.5rem;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    }

    .buttonContainer {
      margin-bottom: 1rem;
      min-width: 200px;
    }

    .button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: calc(100vw - 40px);
      min-height: 45px;
    }

    .title {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 1.575rem;
      font-weight: bold;
    }

    .descriptionContainer {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (min-width: 500px) and (max-width: 767px) {
    .button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      min-width: 300px;
      width: calc(100vw - 80px);
      min-height: 45px;
    }
  }