.alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  background-color: rgb(243, 243, 243);
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  animation: slideDown 0.5s ease-in-out;
  text-align: center;
  box-sizing: border-box; /* Add this line */
}

.alertContent {
  text-align: center;
  max-width: 600px;
  width: 100%; /* Add this line */
}

.alertHeader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
  gap: 20px;
}

.alertTitle {
  font-size: 20px;
  font-weight: bold;
}

.alertText {
  font-size: 20px;
  margin-bottom: 10px;
}

.alertSubText {
  font-size: 14px;
  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.alert button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #FFD42D;
  color: #000;
  border: none;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.alert button:hover {
  background-color: #FFE15D;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}