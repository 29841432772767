@import url('https://fonts.googleapis.com/css2?family=Saira:wght@400;700&display=swap');

.mainContainer {
    padding: 20px;
    color: #000000;
    font-family: 'Saira', sans-serif;
}

.innerContent {
    width: 100%;
    margin: 0 auto;
}

.createFictionContainer {
    width: 100%;
    margin: 0 auto;
    min-height: 400px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-direction: column;
}

.formGroup {
    margin-bottom: 20px;
}

.label {
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}

.textInput,
.fileInput,
.select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.submitButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    align-self: flex-start;
}

.submitButton:hover {
    background-color: #0056b3;
}

.createFictionContainer h3 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 1.2em;
    font-weight: bold;
}

.firstChapterContainer {
    margin-top: 30px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 5px;
}

.firstChapterTitle {
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
}

.chapterTitleInput {
    font-size: 1.1em;
    font-weight: bold;
}