.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modalContent {
    background-color: var(--modal-bg-color);
    color: var(--modal-text-color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 90%;
}

button {
    background-color: var(--button-bg-color);
    color: var(--button-text-color);
    border: none;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: var(--button-hover-bg-color);
}

/* Light Theme */
.lightMode {
    --modal-bg-color: #ffffff;
    --modal-text-color: #000000;
    --button-bg-color: #007bff;
    --button-text-color: #ffffff;
    --button-hover-bg-color: #0056b3;
}

/* Dark Theme */
.darkMode {
    --modal-bg-color: #333333;
    --modal-text-color: #ffffff;
    --button-bg-color: #1e90ff;
    --button-text-color: #ffffff;
    --button-hover-bg-color: #1c7ed6;
}
