@font-face {
    font-family: 'Saira';
    src: url('../assets/fonts/Saira.ttf') format('truetype');
}

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f3f3f3;
    color: #333;
    font-family: 'Saira', sans-serif;
}

.innerContent {
    width: 100%;
    border-radius: 8px;
    text-align: center;
    box-sizing: border-box;
}

.announcementBanner {
    width: 100%;
    border-radius: 8px;
    text-align: center;
    box-sizing: border-box;
    max-height: 400px;
    overflow: hidden;
    position: relative;
    transition: transform 0.5s ease-in-out;
}

.announcementBanner.left {
    transform: translateX(-100%);
}

.announcementBanner.right {
    transform: translateX(100%);
}

.announcementBanner::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    pointer-events: none;
}

.announcementBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the full height */
    object-position: center; /* Center the image */
    border-radius: 4px;
    transform: none; /* Remove any previous transform */
}

.announcementText {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.7); /* Darker box around the text */
    color: #ffffff;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 2;
    max-width: 500px;
    font-size: 16px; /* Smaller text */
}

.pagination {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 5px;
    z-index: 2;
}

.paginationDot {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
}

.paginationDot.active {
    background-color: rgb(255, 255, 255);
}

.fictionsBarContainer {
    margin-top: 20px;
    background-color: #ffffff;
    margin-bottom: 20px;
    border-radius: 10px;
    align-items: center;
    overflow-x: auto;
    padding: 20px;
}

.fictionsBarTitle {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    border-bottom: 2px solid #414141a6; /* Added border under the title */
}

.fictionsBar {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    overflow-x: auto;
}

.fictionCard {
    position: relative;
    background-color: #f9f9f9;
    border-radius: 7px;
    overflow: hidden;
}

.fictionCard img {
    width: 200px;
    height: auto;
    display: block;
}

.fictionDetails {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.fictionCard:hover .fictionDetails {
    opacity: 1;
}

.fictionTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: auto;
    text-align: center;
}

.fictionChapters {
    font-size: 10px;
    color: #000000;
}

.readNowButton {
    background-color: #119b3b;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
}

.readNowButton:hover {
    background-color: #0c7c2e;
}

@media (max-width: 1000px) {
    .announcementBanner {
        border-radius: 0; /* No rounded edges for mobile */
        max-height: 300px; /* Reduce the max height for mobile */
    }

    .announcementBanner img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

@media (max-width: 700px) {
    
    .announcementBanner {
        border-radius: 0; /* No rounded edges for mobile */
        max-height: 250px; /* Reduce the max height for mobile */
    }

    .announcementBanner img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

@media (max-width: 580px) {
    .announcementBanner img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

@media (max-width: 500px) {
    .mainContainer {
        padding-left: 0;
        padding-right: 0;
    }

    .announcementText {
        font-size: 12px; /* Smaller text for mobile */
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .announcementBanner {
        border-radius: 0; /* No rounded edges for mobile */
        max-height: 200px; /* Reduce the max height for mobile */
    }

    .announcementBanner img {
        width: 150%;
        height: 100%;
        object-fit: cover;
        transform: translate(0%, -22%);
    }

    .fictionCard {
        width: 100px; /* Smaller width for mobile */
        height: 150px; /* Smaller height for mobile */
    }

    .fictionCard img {
        width: 100px;
        height: auto;
        display: block;
    }

    .fictionTitle {
        font-size: 14px; /* Smaller font size for mobile */
    }

    .readNowButton {
        padding: 5px 10px; /* Smaller padding for mobile */
        font-size: 12px; /* Smaller font size for mobile */
    }

    .fictionsBarContainer {
        border-radius: 0;
    }
}