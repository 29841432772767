.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
}

.spinner {
    width: 70px;
    height: 70px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner::after {
    content: '';
    width: 50px;
    height: 50px;
    background-image: url('../assets/gifs/loading.gif');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}