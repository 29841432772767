.fill {
    height: 100%;
    height: 100vh;
    height: 100svh;
    width: 100%;
    padding: 0 15.5%;
    margin: 0 auto;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    z-index: 0;
}

/* Custom scrollbar styles */
.fill::-webkit-scrollbar {
    width: 16px;
    background-color: #555555; /* Dark gray background for scrollbar */
}

.fill::-webkit-scrollbar-thumb {
    background: #aaa;
    border: 4px solid rgba(0,0,0,0);
    background-clip: padding-box;
    border-radius: 8px;
}

.fill::-webkit-scrollbar-thumb:hover {
    background: #999;
    border: 4px solid rgba(0,0,0,0);
    background-clip: padding-box;
    border-radius: 8px;
}

.fill::-webkit-scrollbar-corner {
    background-color: #555555; /* Dark gray background for scrollbar */
}

.fill::-webkit-scrollbar-button:single-button {
    background-color: #555555; /* Dark gray background for scrollbar */
    display: block;
    border-style: solid;
}

.fill::-webkit-scrollbar-button:single-button:vertical:decrement {
    height: 13px;
    width: 16px;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #aaa transparent;
}

.fill::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    border-color: transparent transparent #777777 transparent;
}

.fill::-webkit-scrollbar-button:single-button:vertical:decrement:active {
    border-color: transparent transparent #888888 transparent;
}

.fill::-webkit-scrollbar-button:single-button:vertical:increment {
    height: 13px;
    width: 16px;
    border-width: 8px 8px 0 8px;
    border-color: #aaa transparent transparent transparent;
}

.fill::-webkit-scrollbar-button:single-button:vertical:increment:hover {
    border-color: #777777 transparent transparent transparent;
}

.fill::-webkit-scrollbar-button:single-button:vertical:increment:active {
    border-color: #555555 transparent transparent transparent;
}

#homeContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

@media (max-width: 1000px) {
    .fill {
        width: 100svw;
        height: 100svh;
        padding: 0 0;
        overflow-y: auto;
    }

    /* Mobile scrollbar styles */
    .fill::-webkit-scrollbar {
        width: 4px;
        background: #555555; /* Dark gray background for scrollbar */
    }

    .fill::-webkit-scrollbar-thumb {
        background: #aaa;
        border: none;
        border-radius: 4px;
    }

    .fill::-webkit-scrollbar-thumb:hover {
        background: #999;
    }

    .fill::-webkit-scrollbar-button:single-button {
        display: none;
    }

    .fill {
        scrollbar-width: thin;
        scrollbar-color: #aaa #555555; /* Dark gray background for scrollbar */
    }

    .fill::-webkit-scrollbar {
        display: none;
    }
}
