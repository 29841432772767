.fill {
    display: flex;
    height: 100svh;
}

#homeContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: auto;
    padding-left: 245px;
}

.contentContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    z-index: 0;
}

.accessDeniedContainer {
    display: flex;
    flex-direction: column; /* Stack items on top of each other */
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 8px;
    background-color: #ffffff; /* White background */
    margin: 10px; /* Add margin */
    align-items: flex-start; /* Align items to the left */
}

.accessDeniedContainer button {
    background-color: #007BFF; /* Blue background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    align-self: flex-start; /* Align the button to the left */
    margin-top: 10px; /* Add margin to the top */
}

.accessDeniedContainer button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: scale(1.05); /* Slightly enlarge the button on hover */
}


@media (max-width: 900px) {
    #homeContainer {
        padding-left: 0;
    }
}