@import url('https://fonts.googleapis.com/css2?family=Saira:wght@400;700&display=swap');

.mainContainer {
    padding: 20px;
    color: #ffffff;
}

.innerContent {
    width: 100%;
    margin: 0 auto;
}

.innerContainer {
    width: 100%;
    margin: 0 auto;
    min-height: 400px;
    background-color: #2c2c2c;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
}

.title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
}

.inputContainer {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.inputField {
    flex: 1;
    padding: 10px;
    border: 1px solid #555;
    border-radius: 5px;
    background-color: #3c3c3c;
    color: #fff;
    font-size: 1em;
}

.fetchButton {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #1e90ff;
    color: #fff;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.fetchButton:hover {
    background-color: #1c86ee;
}

.creatorInfo {
    background-color: #3c3c3c;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    margin-bottom: 20px;
}

.creatorInfo h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.creatorInfo a {
    color: #1e90ff;
    text-decoration: none;
    font-weight: bold;
}

.infoContainer {
    background-color: #3c3c3c;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgb(43, 43, 43);
    margin-bottom: 20px;
}

.infoContainer h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.infoContainer a {
    color: #1e90ff;
    text-decoration: none;
    font-weight: bold;
}

.cardsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
}

.card {
    background-color: #3c3c3c;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    width: calc(33.333% - 20px);
    box-sizing: border-box;
}

.card h2 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.card p {
    margin-bottom: 10px;
}


.csvContainer {
    background-color: #2c2c2c;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(43, 43, 43, 0.5);
    margin-top: 20px;
    overflow-x: auto;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 300px; /* Set maximum height */
}

.csvContainer h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.csvTable {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Saira', sans-serif; /* Use the imported font */
    color: #ffffff;
}

.csvTable th, .csvTable td {
    border: 1px solid #555;
    padding: 8px;
    text-align: left;
}

.csvTable th {
    background-color: #3c3c3c;
    font-weight: bold;
}

.csvTable tr:nth-child(even) {
    background-color: #3c3c3c;
}

.saveButton {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.saveButton:hover {
    background-color: #45a049;
}

.saveButton:disabled {
    background-color: #9e9e9e;
    cursor: not-allowed;
}

.tierSetting {
    margin: 10px;
    margin-left: 0px;
    margin-bottom: 20px;
    padding: 10px; /* Added padding */
    padding-left: 20px;
    padding-right: 20px;
    background-color: #2c2c2c;
    border-radius: 5px;
    min-width: 250px;
    display: inline-block;
    vertical-align: top;
    gap: 10px; /* Added gap */
}

.tierSetting h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #ffffff;
}

.tierSetting label {
    display: block;
    margin-bottom: 5px;
    color: #cccccc;
}

.tierSetting input[type="number"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #555;
    border-radius: 5px;
    background-color: #3c3c3c;
    color: #ffffff;
    box-sizing: border-box;
}
