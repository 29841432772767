.card {
    border-radius: 0.5rem;
    border: 1px solid #e2e8f0;
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  
  .cardHeader {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
  }
  
  .cardTitle {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  
  .cardDescription {
    font-size: 0.875rem;
    color: #718096;
  }
  
  .cardContent {
    padding: 1.5rem;
    padding-top: 0;
  }
  
  .cardFooter {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    padding-top: 0;
  }