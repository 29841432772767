.usernameInput {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 300px;
    box-sizing: border-box;
}

.successMessage {
    color: green;
    margin-top: 10px;
}

.errorMessage {
    color: red;
    margin-top: 10px;
}

.saveButton {
    background-color: #28a745; /* Green background */
    color: white; /* White text */
    padding: 10px 20px; /* Padding */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Font size */
    margin-top: 10px; /* Margin on top */
    transition: background-color 0.3s ease; /* Smooth transition */
}

.saveButton:hover {
    background-color: #218838; /* Darker green on hover */
}

.saveButton:active {
    background-color: #1e7e34; /* Even darker green on click */
}