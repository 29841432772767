.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998; /* Highest z-index */
}

.modalContent {
    background: #333;
    color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    max-height: 80%; /* Set maximum height */
    overflow: hidden; /* Hide overflow */
    display: flex;
    flex-direction: column; /* Ensure flex layout */
    position: relative;
    
}

.modalBody {
    overflow-y: auto; /* Add vertical scroll when content overflows */
    margin-top: 10px;
    margin-bottom: 10px;
    flex-grow: 1;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: white; /* Make the close button white */
}
  
.modalContent small,
.modalContent h2,
.modalContent div,
.modalContent small {
    display: block;
    margin: 10px 0;
}