.mobileNavBar {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    align-items: center;
    font-family: 'Saira', sans-serif;
}

.topRow, .bottomRow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    font-family: 'Saira', sans-serif;
    padding-top: 5px;
    padding-bottom: 5px; 
}

.topRow {
    justify-content: space-between;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.logoContainer {
    display: flex;
    align-items: center;
}

.logo {
    height: 40px;
    margin-right: 10px;
}

.logoText {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Saira', sans-serif;
}

.hamburgerMenu {
    cursor: pointer;
}

.icon {
    cursor: pointer;
    margin: 0 10px;
}

.hamburgerIcon {
    cursor: pointer;
    margin-right: 15px;
}

.dropdownMenu {
    display: none;
    flex-direction: column;
    background-color: white;
    width: 100%;
    padding: 10px 20px;
    border-top: 1px solid #e0e0e0;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.dropdownMenu.show {
    display: flex;
}

.dropdownItem {
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
}

.searchBar {
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.searchInput {
    flex: 1;
    padding: 10px; /* Increased padding to make it thicker vertically */
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.searchButton {
    padding: 10px 10px; /* Increased padding to make it thicker vertically */
    margin-left: 5px;
    background-color: #00bcd4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.searchButton:hover {
    background-color: #0097a7; /* Darker shade of the original background color */
}

.searchButton:active {
    background-color: #007c91; /* Even darker shade for on press */
}

.userButton {
    background-color: #333;
    color: white;
    border: none;
    padding: 14px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.userButton:hover {
    background-color: #4f4f4f;
}

.userButton .arrow {
    margin-left: 8px;
    transition: transform 0.3s;
}

.userButton .arrow.down {
    transform: rotate(90deg);
}

.profileDropdownMenu {
    position: absolute;
    top: 100px;
    right: 10px;
    color: #000000;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    font-family: 'Saira', sans-serif;
    min-width: 160px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
    z-index: 1000;
}

.profileDropdownItem {
    color: black;
    padding: 12px 20px;
    cursor: pointer;
    white-space: nowrap;
    font-family: 'Saira', sans-serif;
    background-color: white; /* Set the background color to white */
    border: none; /* Remove borders */
    border-radius: 0; /* Make the edges sharp */
    display: flex; /* Use flexbox to center vertically */
    align-items: center; /* Center items vertically */
    width: 100%; /* Make the dropdown items take up the full width */
    text-align: left; /* Align text to the left */
    z-index: 1000;
}

.profileDropdownItem img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    align-self: center;
}

.profileDropdownItem:hover {
    color: white;
    background-color: rgb(92, 160, 199); /* Change background color to gray on hover */
    text-align: left; /* Align text to the left */
    z-index: 1000;
}