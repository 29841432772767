.container {
  background-color: #2d3748; /* bg-gray-800 */
  color: white;
  padding: 1.5rem; /* md:p-6 */
  border-radius: 0.5rem; /* rounded-lg */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); /* shadow-lg */
  margin: 0 auto; /* mx-auto */
}

.flexContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flexContainerMd {
  flex-direction: row;
  align-items: flex-start; /* Align items to the start */
  justify-content: space-between;
}

.coverImage {
  border-radius: 0.375rem; /* rounded-md */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); /* shadow-md */
  width: 7.5rem; /* w-30 */
  height: 10rem; /* h-40 */
  object-fit: cover;
}

.titleMd {
  font-size: 1.875rem; /* md:text-3xl */
  margin-top: 0;
  margin-bottom: 0;
}

.textContainer {
  flex-grow: 1; /* flex-grow */
  display: flex;
  flex-direction: column; /* flex-col */
  justify-content: space-between; /* justify-between */
}

.title {
  font-size: 1.5rem; /* text-2xl */
  font-size: 1.875rem; /* md:text-3xl */
  font-weight: bold; /* font-bold */
}

.author {
  font-size: 1.125rem; /* text-lg */
  align-self: center; /* self-center */
  margin-bottom: 0;
}

.chapter {
  font-size: 1.25rem; /* text-xl */
  font-size: 1.5rem; /* md:text-2xl */
  font-weight: 600; /* font-semibold */
  align-self: center; /* self-end */
  margin-bottom: 0;
}

.ratingContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* gap-2 */
  margin-bottom: 0.5rem; /* mb-2 */
}

.star {
  width: 1.25rem; /* w-5 */
  height: 1.25rem; /* h-5 */
}

.starFilled {
  color: #f6e05e; /* text-yellow-400 */
  fill: currentColor;
}

.starEmpty {
  color: #a0aec0; /* text-gray-400 */
}

.chapterMd {
  font-size: 1.5rem; /* md:text-2xl */
}

.buttonContainer {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 0px; /* gap-2 */
  align-items: flex-start; /* Align buttons to the right */
}

.button {
  font-weight: bold;
  padding: 5px;
  margin-top: 1px;
  border-radius: 2px; /* rounded */
  border-color: #ffffff00;
  color: white;
  min-width: 200px; /* Set minimum width */
  min-height: 36px;
  cursor: pointer;
}

.buttonBlue {
  background-color: #3182ce; /* bg-blue-500 */
}

.buttonBlue:hover {
  background-color: #2f7ac0; /* hover:bg-blue-600 */
}

.buttonGreen {
  background-color: #48bb78; /* bg-green-500 */
}

.buttonGreen:hover {
  background-color: #38a169; /* hover:bg-green-600 */
}

.buttonYellow {
  background-color: #ffffff; /* bg-yellow-500 */
  color: #000000;
}

.buttonYellow:hover {
  background-color: #d3d3d3; /* hover:bg-yellow-600 */
  color: #000000;
}

.buttonRed {
  background-color: #e53e3e; /* bg-red-500 */
}

.buttonRed:hover {
  background-color: #cf3131; /* hover:bg-red-600 */
}