@import url('https://fonts.googleapis.com/css2?family=Saira:wght@400;700&display=swap');

.mainContainer {
    padding: 20px;
    color: #ffffff;
    font-family: 'Saira', sans-serif;
}

.innerContent {
    width: 100%;
    margin: 0 auto;
}

.statisticsBar {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-top: 20px;
    flex-wrap: nowrap;
}

.metric {
    text-align: center;
    background-color: #2e2e2e;
    border-radius: 10px;
    padding: 10px;
    flex: 1;
    min-width: 200px;
    max-width: 100%;
}

.metric:not(:first-child):not(:last-child) {
    margin-left: 20px;
    margin-right: 20px;
}

.metricLabel {
    font-size: 14px;
    color: #aaaaaa;
    display: block;
}

.metricValue {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    display: block;
}

.fictionsBar {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    background-color: #2e2e2e;
    border-radius: 10px;
    padding: 20px;
    align-items: center;
    overflow-x: auto;
}

.addNewButton {
    background-color: #0d5985;
    color: #ffffff;
    border: none;
    cursor: pointer;
    border-radius: 7px;
    width: 200px;
    height: 300px;
    font-size: 16px;
    font-weight: bold;
}

.addNewButton:hover {
    background-color: #0c4d6e;
}

.fictionCard {
    position: relative;
    background-color: #3a3a3a;
    border-radius: 7px;
    overflow: hidden;
}

.fictionCard img {
    width: 200px;
    height: auto;
    display: block;
}

.fictionDetails {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.fictionCard:hover .fictionDetails {
    opacity: 1;
}

.fictionTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: auto;
    word-wrap: break-word;
}

.fictionChapters, .fictionComments, .fictionPlaceholder {
    font-size: 10px;
    color: #ffffff;
}

.fictionChaptersLabel, .fictionCommentsLabel, .fictionPlaceholder1Label, .fictionPlaceholder2Label {
    color: #FFD700;
    display: inline-block;
    margin-right: 5px;
}

.fictionChaptersValue, .fictionCommentsValue, .fictionPlaceholder1Value, .fictionPlaceholder2Value {
    display: inline-block;
}

.fictionChaptersContainer, .fictionCommentsContainer, .fictionPlaceholder1Container, .fictionPlaceholder2Container {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    width: 85%;
}

.fictionPlaceholder2Container {
    margin-bottom: 15px;
}

.fictionButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
    margin-top: auto;
    margin-bottom: 40px;
}

.fictionButtons button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.fictionButtons button:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.fictionButtons button:nth-child(2) {
    border-radius: 0;
}

.fictionButtons button:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.fictionButtons button:hover {
    background-color: #0056b3;
}

.bottomSection {
    display: flex;
    justify-content: space-between;
}

.recentActivityFeed {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.recentReviews, .recentComments {
    width: 48%;
    background-color: #2e2e2e;
    padding: 10px;
    border-radius: 14px;
    overflow-y: auto;
}

.reviewItem, .commentItem {
    background-color: #3a3a3a;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
}

.reviewLeft {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    justify-content: flex-start;
    flex: 1;
}

.reviewRight {
    flex: 6;
}

.reviewerName, .commenterName {
    font-weight: bold;
    color: #ffffff;
}

.reviewFictionTitle, .commentFictionTitle {
    font-size: 14px;
    color: #aaaaaa;
}

.reviewDate, .commentDate {
    font-size: 12px;
    color: #777777;
    float: right;
}

.reviewSnippet, .commentSnippet {
    font-size: 14px;
    color: #cccccc;
}

.reviewRating {
    font-size: 16px;
    color: #FFD700;
    margin-top: 5px;
}

.reviewTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
    color: #ffffff;
}

.reviewMeta {
    font-size: 14px;
    color: #aaaaaa;
    margin-top: 0;
}

.reviewMeta span {
    font-weight: bold;
}

.sectionTitle {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
    margin-left: 10px;
}

.commenterName {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
}

.commentTitleRow {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 10px;
}

.commentFictionTitle {
    font-size: 14px;
    margin: 0;
    font-weight: bold;
    color: #ffffff;
}

.commentDate {
    font-size: 12px;
    color: #777777;
    margin: 0;
    float: right;
    font-weight: bold;
}

.commentSnippet {
    font-size: 14px;
    color: #cccccc;
    margin-top: 5px;
    margin-bottom: 10px;
}

.commentActions {
    display: flex;
    justify-content: flex-start;
}

.commentActions button {
    background: none;
    border: none;
    color: #007BFF;
    cursor: pointer;
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.commentActions button:hover {
    background-color: #333333;
    border-radius: 4px;
}

.commentItem {
    display: flex;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 8px;
}

.commentLeft {
    margin-right: 15px;
}

.profilePicture {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}

.profileCommentPicture {
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 1px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}

.commentRight {
    flex: 1;
}