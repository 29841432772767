.badge {
    display: inline-flex;
    align-items: center;
    border-radius: 9999px;
    padding: 0.125rem 0.625rem;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  }
  
  .badge:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
  }
  
  .variant-default {
    background-color: #3182ce;
    color: white;
  }
  
  .variant-secondary {
    background-color: #edf2f7;
    color: #4a5568;
  }
  
  .variant-destructive {
    background-color: #e53e3e;
    color: white;
  }
  
  .variant-outline {
    background-color: transparent;
    border: 1px solid #e2e8f0;
    color: #4a5568;
  }