.App {
    display: flex;
    flex-direction: column;
    width: 100svw;
    height: 100svh;
    bottom: 0px;
    left: 0px;
    font-family: Rubik;
    background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('./assets/images/bg3.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.appContainer {
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
}

.fill {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.sessionButton {
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: black;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 17px;
}