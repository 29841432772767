@font-face {
    font-family: 'Saira';
    src: url('../assets/fonts/Saira.ttf') format('truetype');
}

.navBar {
    background-color: #333;
    color: white;
    overflow: hidden;
    padding: 0%;
    font-family: 'Saira', sans-serif;
    width: 100svw;
    box-sizing: border-box;
    transition: top 0.3s;
    position: relative;
    padding: 1px 25%;
    left: calc((100% - 100svw) / 2);
    right: 0;
    min-height: 120px;
}

.navContainer {
    width: 100svw;
    box-sizing: border-box;
}

.topRow, .bottomRow {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    padding: 0;
    flex-wrap: nowrap;
    position: relative;
    width: 100svw;
    left: calc((100% - 100svw) / 2);
    right: 0;
    min-height: 60px;
    overflow: visible;
}

.insideRow {
    display: flex;
    align-items: center;
    width: 100svw;
    box-sizing: border-box;
    padding-left: 16%;
    padding-right: 16%;
    flex-wrap: nowrap;
    position: relative;
    justify-content: space-between;
}

.topRow {
    background-color: #ffffff;
    color: #000000;
    justify-content: flex-end;
}

.bottomRow {
    background-color: #515452;
}

.logo {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: auto;
}

.userSection {
    display: flex;
    align-items: center;
    position: relative;
}

.notifications, .messages, .userIcon {
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.navLinks {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.navLinks li {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.navLinks li a {
    color: white;
    text-decoration: none;
    padding: 14px 16px;
    display: flex;
    align-items: center;
}

.navLinks li a:hover {
    background-color: #111;
}

.navLinks li img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    align-self: center;
}

.searchBar {
    padding: 10px;
    border: none;
    border-radius: 4px;
    flex-shrink: 0;
}

.logoImage {
    width: 50px;
    height: auto;
}

.dropdownMenu {
    position: absolute;
    top: 55px;
    right: 16%;
    color: #000000;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    font-family: 'Saira', sans-serif;
    min-width: 160px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    z-index: 1000;
}

.dropdownItem {
    color: black;
    padding: 12px 20px;
    cursor: pointer;
    white-space: nowrap;
    font-family: 'Saira', sans-serif;
    background-color: white; /* Set the background color to white */
    border: none; /* Remove borders */
    border-radius: 0; /* Make the edges sharp */
    display: flex; /* Use flexbox to center vertically */
    align-items: center; /* Center items vertically */
    width: 100%; /* Make the dropdown items take up the full width */
    text-align: left; /* Align text to the left */
    z-index: 1000;
}

.dropdownItem img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    align-self: center;
}

.dropdownItem:hover {
    color: white;
    background-color: rgb(92, 160, 199); /* Change background color to gray on hover */
    text-align: left; /* Align text to the left */
    z-index: 1000;
}

.userButton {
    background-color: #33333300;
    color: rgb(0, 0, 0);
    border: none;
    padding: 14px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.userButton:hover {
    color: rgb(29, 87, 121);
    background-color: lightblue; /* Change text color to light blue on hover */
}

.userButton .arrow {
    margin-left: 8px;
    transition: transform 0.3s;
}

.userButton .arrow.down {
    transform: rotate(90deg);
}

.mobileMenuButton {
    display: none;
    cursor: pointer;
    margin-left: 20px;
}

@media (max-width: 500px) {
    .bottomRow {
        display: none;
    }

    .mobileMenuButton {
        display: flex;
    }
}

@media (max-width: 350px) {
    .topRow {
        flex-direction: column;
        align-items: flex-start;
    }

    .insideRow {
        flex-direction: column;
        align-items: flex-start;
    }

    .userSection {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
}