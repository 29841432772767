.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
    cursor: pointer;
  }
  
  .button:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
  }
  
  .button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Variants */
  .variant-default {
    background-color: #3182ce;
    color: white;
  }
  
  .variant-default:hover {
    background-color: #2c5282;
  }

  .variant-dark-default {
    background-color: #3182ce;
    color: white;
    border: 2px solid #3182ce;
  }
  
  .variant-dark-default:hover {
    background-color: #36639b;
    color: #a6d3fd;
    border: 2px solid #36639b;
  }
  
  .variant-destructive {
    background-color: #e53e3e;
    color: white;
  }
  
  .variant-destructive:hover {
    background-color: #c53030;
  }
  
  .variant-outline {
    background-color: transparent;
    border: 1px solid #e2e8f0;
    color: #4a5568;
  }
  
  .variant-outline:hover {
    background-color: #edf2f7;
  }

  .variant-outlinewhite {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
  
  .variant-outlinewhite:hover {
    background-color: #edf2f7;
    color: #000000;
  }
  
  .variant-secondary {
    background-color: #edf2f7;
    color: #4a5568;
  }
  
  .variant-secondary:hover {
    background-color: #e2e8f0;
  }
  
  .variant-ghost {
    background-color: transparent;
    color: #4a5568;
  }
  
  .variant-ghost:hover {
    background-color: #edf2f7;
  }
  
  .variant-link {
    background-color: transparent;
    color: #3182ce;
    text-decoration: underline;
  }
  
  .variant-link:hover {
    color: #2c5282;
  }
  
  /* Sizes */
  .size-default {
    height: 2.5rem;
    padding: 0.5rem 1rem;
  }
  
  .size-sm {
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    font-size: 0.75rem;
  }
  
  .size-lg {
    height: 2.75rem;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }