@import url('https://fonts.googleapis.com/css2?family=Saira:wght@400;700&display=swap');

.mainContainer {
    padding: 20px;
    color: #000000;
    font-family: 'Saira', sans-serif;
}

.innerContent {
    width: 100%;
    margin: 0 auto;
}

.formContainer {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
}

.form {
    display: flex;
    flex-direction: column;
}

.formGroup {
    margin-bottom: 15px;
}

.label {
    font-weight: 700;
    margin-bottom: 5px;
    display: block;
}

.textInput {
    width: 98.5%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.buttonGroup {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.previewButton, .saveDraftButton, .publishButton {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.previewButton {
    background-color: #f0614e;
    color: #fff;
}

.saveDraftButton {
    background-color: #a1a1a1;
    color: #fff;
}

.publishButton {
    background-color: #5cb85c;
    color: #fff;
}

.datePicker {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}
