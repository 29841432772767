@import url('https://fonts.googleapis.com/css2?family=Saira:wght@400;700&display=swap');

.mainContainer {
    padding: 20px;
    color: #000000;
    font-family: 'Saira', sans-serif;
}

.innerContent {
    width: 100%;
    margin: 0 auto;
}

.submissionsContainer {
    width: 100%;
    margin: 0 auto;
    min-height: 400px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
}


.submissionsList {
    list-style-type: none;
    padding: 0;
}

.submissionItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
}

.submissionTitle {
    font-weight: bold;
    flex: 1;
}

.submissionDate {
    text-align: right;
}


.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popupContent {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    min-width: 1000px;
    margin-left: 250px;
}

.popupContent h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
}

.popupContent h3 {
    font-size: 1.4em;
    margin-top: 20px;
    margin-bottom: 10px;
}

.coverImage {
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
}

.popupContent p {
    margin-bottom: 10px;
}

.popupContent button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
}

.popupContent button:hover {
    background-color: #0056b3;
}

.flexContainer {
    display: flex;
    gap: 20px; /* Adjust the gap as needed */
}

.synopsis {
    flex: 1;
}

.buttonContainer {
    display: flex;
    gap: 10px;
}

.buttonContainer button:nth-child(1) {
    background-color: #28a745;
}

.buttonContainer button:nth-child(2) {
    background-color: #dc3545;
}

.buttonContainer button:nth-child(3) {
    background-color: #6c757d;
}

.buttonContainer button:hover {
    opacity: 0.8;
}
