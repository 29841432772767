.drawerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  display: flex;
  justify-content: flex-end;
}

.drawerContentBottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1002;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.themeCircles {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 450px;
  margin: 5px 0;
}

.themeCircle {
  width: 50px;
  border: 2px solid #ccc;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s;
}

.themeCircle:hover {
  transform: scale(1.1);
}

.lightTheme {
  background: white;
}

.paleBeigeTheme {
  background: #f9f9e6;
}

.parchmentTheme {
  background: #f5eedb;
}

.darkTheme {
  background: #242424;
}

.customSlider {
  position: relative;
  width: 90%;
  padding: 10px 0;
  max-width: 450px;
  height: 33px;
  background: #8ab8e4;
  border-radius: 20px;
  margin: 10px 0;
  cursor: pointer;
  overflow: hidden;
  touch-action: none;
}

.customSliderFill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #3182ce;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sliderLabel {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 30px;
  color: white;
  font-size: 16px;
  pointer-events: none;
}

.chapterNav {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.navButton {
  background-color: #3182ce;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.navButton a {
  color: white;
  text-decoration: none;
}

.navButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.navButton:hover:not(:disabled) {
  background-color: #2a70b1;
}