.card {
    width: 100%;
    margin: 2rem auto;
    background-color: #e2e2e2;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    text-align: left;
  }
  
  .cardContent {
    padding: 1.5rem;
    text-align: left;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .title {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: left;
    padding: 0;
    margin: 0;
  }
  
  .button {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--foreground);
    background-color: transparent;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.2s;
    text-align: left;
  }
  
  .button:hover {
    background-color: var(--accent);
  }
  
  .icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    text-align: left;
  }
  
  .srOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    text-align: left;
  }
  
  .buttonText {
    display: none;
    text-align: left;
  }
  
  @media (min-width: 640px) {
    .buttonText {
      display: inline;
      text-align: left;
    }
  }
  
  .content {
    overflow: hidden;
    max-height: 5rem;
    transition: max-height 0.3s ease-in-out;
    text-align: left;
  }
  
  .content.expanded {
    max-height: 1000px;
    text-align: left;
  }
  
  .text {
    line-height: 1.5;
    text-align: left;
    padding: 0;
    margin: 0;
  }

  a {
    color: #337ab7;
    text-decoration: underline;
  }